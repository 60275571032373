import { apiUrl } from '@/config/global'

export default class FormService{
  constructor(http){
    this._http = http
  }

  getTerm(){
    return fetch(`${apiUrl}/FormDealer/downloadTermAccept`, {
      method: "get"
    }).then(response => response.blob())
  }

  getSocialContract(id){
    return fetch(`${apiUrl}/FormDealer/downloadSocialContract/${id}`, {
      method: "get"
    }).then(response => response.blob())
  }
    
  getByLink (user) {    
    let formData = new FormData();
    formData.append('link', user)
    return fetch(`${apiUrl}/FormDealer/validateLink`, {
      method: "post",
      body: formData
    }).then(response => response.json())
  }
    
  generateLink (id) {    
    let formData = new FormData();
    formData.append('userToken.FormDealerId', id)
    return fetch(`${apiUrl}/FormDealer/generateLink`, {
      method: "post",
      body: formData
    }).then(response => response.json())
  }

  getInfo (){
    return fetch(`${apiUrl}/FormDealer/getConfigBrand`, {
      method: "get"
    }).then(response => response.json())
  }
    
  upload (files) {
    let formData = new FormData();
    for (let index = 0; index < files.length; index++) {
      formData.append(`files`, files[index])
    }

    return fetch(`${apiUrl}/FormDealer/uploadFiles`, {
      method: "post",
      body: formData
    }).then(response => response.json())
  }
    
  create (fiat, jeep) {
    console.log(fiat)
    console.log(jeep)
    let cont = 0
    let formData = new FormData();

    if (fiat.selected) {
      formData.append(`items[${cont}].StatusId`, 3)
      formData.append(`items[${cont}].FantasyName`, fiat.fantasyName)
      formData.append(`items[${cont}].CompanyName`, fiat.companyName)
      formData.append(`items[${cont}].Business`, fiat.business)
      formData.append(`items[${cont}].CNPJ`, fiat.cNPJ)
      formData.append(`items[${cont}].MunicipalRegistration`, fiat.municipalRegistration)
      formData.append(`items[${cont}].StateRegistration`, fiat.stateRegistration)
      formData.append(`items[${cont}].Street`, fiat.street)
      formData.append(`items[${cont}].NumberAddress`, fiat.numberAddress)
      formData.append(`items[${cont}].Complement`, fiat.complement)
      formData.append(`items[${cont}].ZipCode`, fiat.zipCode)
      formData.append(`items[${cont}].District`, fiat.district)
      formData.append(`items[${cont}].City`, fiat.city)
      formData.append(`items[${cont}].State`, fiat.state)
      formData.append(`items[${cont}].Website`, fiat.website)
  
      formData.append(`items[${cont}].FinancialContractName`, fiat.financialContractName)
      formData.append(`items[${cont}].FinancialContractMail`, fiat.financialContractMail)
      formData.append(`items[${cont}].FinancialContractPhone`, fiat.financialContractPhone)
  
      formData.append(`items[${cont}].LegalRepresentativeName`, fiat.legalRepresentativeName)
      formData.append(`items[${cont}].legalRepresentativeMail`, fiat.legalRepresentativeMail)
      formData.append(`items[${cont}].LegalRepresentativePhone`, fiat.legalRepresentativePhone)
  
      formData.append(`items[${cont}].MediumContractName`, fiat.mediumContractName)
      formData.append(`items[${cont}].MediumContractMail`, fiat.mediumContractMail)
      formData.append(`items[${cont}].MediumContractPhone`, fiat.mediumContractPhone)
  
      formData.append(`items[${cont}].Brand`, fiat.brand)
      formData.append(`items[${cont}].NumberStores`, fiat.numberStores)
      formData.append(`items[${cont}].Model`, fiat.model)
  
      formData.append(`items[${cont}].FileNameSocialContract`, fiat.fileNameSocialContract)
      cont++
    }

    if (jeep.selected) {
      formData.append(`items[${cont}].StatusId`, 3)
      formData.append(`items[${cont}].FantasyName`, jeep.fantasyName)
      formData.append(`items[${cont}].CompanyName`, jeep.companyName)
      formData.append(`items[${cont}].Business`, jeep.business)
      formData.append(`items[${cont}].CNPJ`, jeep.cNPJ)
      formData.append(`items[${cont}].MunicipalRegistration`, jeep.municipalRegistration)
      formData.append(`items[${cont}].StateRegistration`, jeep.stateRegistration)
      formData.append(`items[${cont}].Street`, jeep.street)
      formData.append(`items[${cont}].NumberAddress`, jeep.numberAddress)
      formData.append(`items[${cont}].Complement`, jeep.complement)
      formData.append(`items[${cont}].ZipCode`, jeep.zipCode)
      formData.append(`items[${cont}].District`, jeep.district)
      formData.append(`items[${cont}].City`, jeep.city)
      formData.append(`items[${cont}].State`, jeep.state)
      formData.append(`items[${cont}].Website`, jeep.website)
  
      formData.append(`items[${cont}].FinancialContractName`, jeep.financialContractName)
      formData.append(`items[${cont}].FinancialContractMail`, jeep.financialContractMail)
      formData.append(`items[${cont}].FinancialContractPhone`, jeep.financialContractPhone)
  
      formData.append(`items[${cont}].LegalRepresentativeName`, jeep.legalRepresentativeName)
      formData.append(`items[${cont}].legalRepresentativeMail`, jeep.legalRepresentativeMail)
      formData.append(`items[${cont}].LegalRepresentativePhone`, jeep.legalRepresentativePhone)
  
      formData.append(`items[${cont}].MediumContractName`, jeep.mediumContractName)
      formData.append(`items[${cont}].MediumContractMail`, jeep.mediumContractMail)
      formData.append(`items[${cont}].MediumContractPhone`, jeep.mediumContractPhone)
  
      formData.append(`items[${cont}].Brand`, jeep.brand)
      formData.append(`items[${cont}].NumberStores`, jeep.numberStores)
      formData.append(`items[${cont}].Model`, jeep.model)
  
      formData.append(`items[${cont}].FileNameSocialContract`, jeep.fileNameSocialContract)
      cont++
    }

    formData.append('statusId', 3)

    return fetch(`${apiUrl}/FormDealer/create`, {
      method: "post",
      body: formData
    }).then(response => response.json())
  }
    
  update (fiat, jeep) {
    let form = fiat.selected ? fiat : jeep
    let formData = new FormData();
    formData.append('id', form.principalId)
    formData.append('statusId', 3)
    formData.append('items[0].id', form.id)
    formData.append('items[0].StatusId', 3)
    formData.append('items[0].FantasyName', form.fantasyName)
    formData.append('items[0].principalId', form.principalId)
    formData.append('items[0].CompanyName', form.companyName)
    formData.append('items[0].Business', form.business)
    formData.append('items[0].CNPJ', form.cNPJ)
    formData.append('items[0].MunicipalRegistration', form.municipalRegistration)
    formData.append('items[0].StateRegistration', form.stateRegistration)
    formData.append('items[0].Street', form.street)
    formData.append('items[0].NumberAddress', form.numberAddress)

    if (form.complement !== null && form.complement !== undefined && form.complement !== 'null') {
      formData.append('items[0].Complement', form.complement)
    }

    formData.append('items[0].ZipCode', form.zipCode)
    formData.append('items[0].District', form.district)
    formData.append('items[0].City', form.city)
    formData.append('items[0].State', form.state)
    formData.append('items[0].Website', form.website)

    formData.append('items[0].FinancialContractName', form.financialContractName)
    formData.append('items[0].FinancialContractMail', form.financialContractMail)
    formData.append('items[0].FinancialContractPhone', form.financialContractPhone)

    formData.append('items[0].LegalRepresentativeName', form.legalRepresentativeName)
    formData.append('items[0].legalRepresentativeMail', form.legalRepresentativeMail)
    formData.append('items[0].LegalRepresentativePhone', form.legalRepresentativePhone)

    formData.append('items[0].MediumContractName', form.mediumContractName)
    formData.append('items[0].MediumContractMail', form.mediumContractMail)
    formData.append('items[0].MediumContractPhone', form.mediumContractPhone)

    formData.append('items[0].versionForm', form.versionForm)

    formData.append('items[0].Brand', form.brand)
    formData.append('items[0].NumberStores', form.numberStores)
    formData.append('items[0].NumberStores', form.model)

    formData.append('items[0].FileNameSocialContract', form.fileNameSocialContract)

    formData.append('items[0].isMultiBrands', form.isMultiBrands)
    
    if (form.isMultiBrands) {
      formData.append('items[0].multiBrandsKey', form.multiBrandsKey)
    }

    return fetch(`${apiUrl}/FormDealer/update`, {
      method: "post",
      body: formData
    }).then(response => response.json())
  }
}