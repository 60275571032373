<template>
  <svg preserveAspectRatio="xMidYMid meet" data-bbox="43.5 56 112.999 87.999" viewBox="43.5 56 112.999 87.999" height="25" width="20" xmlns="http://www.w3.org/2000/svg" data-type="color" role="img" class="rotate-10n">
    <g>
      <path d="M107.555 87.416H92.13a2.812 2.812 0 0 0-2.819 2.803v11.549a2.811 2.811 0 0 0 2.819 2.803h15.425a2.812 2.812 0 0 0 2.819-2.803v-11.55a2.812 2.812 0 0 0-2.819-2.802z" fill="#008598" data-color="1"></path>
      <path d="M153.128 68.072h-34.793l-.164-8.781A3.363 3.363 0 0 0 114.799 56H85.993a3.362 3.362 0 0 0-3.372 3.353v8.757l-35.788.416a3.363 3.363 0 0 0-3.333 3.353v68.767a3.363 3.363 0 0 0 3.372 3.353h106.255a3.362 3.362 0 0 0 3.372-3.353V71.425c.001-1.852-1.509-3.353-3.371-3.353zm-63.762-5.365h22.123l.151 8.057H89.366v-8.057zM85.3 74.787h30.213c.029 0 .056-.007.085-.009h34.157V86.5h-99.51V75.195l35.055-.408zm-35.055 62.506V90.525h99.51v46.768h-99.51z" fill="#999997" data-color="2"></path>
    </g>
  </svg>
</template>

<script>
export default {
}
</script>

<style>
.rotate-10n {
  transform: rotate(-10deg);
}
</style>