<template>
  <svg preserveAspectRatio="xMidYMid meet" data-bbox="23.5 23.5 153 152.999" viewBox="23.5 23.5 153 152.999" height="20" width="20" xmlns="http://www.w3.org/2000/svg" data-type="color" fill='white' role="img"><defs></defs>
    <g>
      <path d="M57.671 34.013c2.802 0 5.998 1.02 8.46 4.693 6.633 9.883 13.03 19.193 16.572 25.532 1.199 2.151 2.363 8.239-1.553 12.646-3.381 3.798-6.364 4.73-8.165 7.504-1.235 1.912-2.646 6.184-.325 11.096 2.776 5.867 6.162 10.685 13.592 18.234 7.547 7.421 12.364 10.804 18.242 13.581 1.918.905 3.742 1.241 5.363 1.241 2.532 0 4.574-.816 5.735-1.566 2.776-1.799 3.71-4.78 7.511-8.161 2.292-2.033 5.034-2.696 7.443-2.696 2.231 0 4.177.57 5.207 1.148 6.342 3.536 15.657 9.931 25.549 16.558 6.938 4.649 4.408 11.913 3.431 14.196-.982 2.284-6.004 9.841-16.805 15.491-2.972 1.553-6.893 2.476-11.648 2.476-14.968 0-38.155-9.16-65.66-36.651-36.237-36.217-40.635-64.946-34.188-77.26 5.651-10.798 13.213-15.818 15.5-16.795 1.074-.462 3.25-1.267 5.739-1.267zm0-10.513c-4.111 0-7.658 1.158-9.908 2.13-4.45 1.896-13.862 8.598-20.651 21.572-8.484 16.206-4.132 49.387 36.071 89.566 34.585 34.568 60.642 39.731 73.097 39.731 6.301 0 11.86-1.237 16.522-3.674 12.956-6.777 19.673-16.176 21.598-20.661 4.489-10.497 1.645-21.118-7.239-27.075-1.525-1.02-3.036-2.035-4.522-3.034-8.235-5.536-16.013-10.766-21.758-13.972-2.808-1.57-6.577-2.477-10.331-2.479-5.495 0-10.484 1.848-14.427 5.348-2.618 2.327-4.251 4.482-5.442 6.054-.246.325-.552.73-.788 1.02h-.033c-.247 0-.54-.078-.87-.234-4.366-2.062-8.274-4.609-15.298-11.51-6.908-7.024-9.456-10.928-11.519-15.292-.191-.403-.24-.699-.237-.9.29-.235.698-.543 1.025-.79 1.574-1.191 3.729-2.82 6.053-5.43 6.654-7.492 6.45-18.339 2.884-24.745-3.234-5.787-8.505-13.615-14.088-21.903-.968-1.439-1.953-2.899-2.942-4.374C70.824 26.82 64.717 23.5 57.671 23.5z" fill="white" data-color="1"></path>
    </g>
  </svg>
</template>

<script>
export default {
}
</script>

<style>
</style>