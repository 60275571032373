<template>
  <div class="bg-html">
    <Header />
    <main class="w-75 mx-auto mt-4 mb-4">
      <b-row>
        <b-col cols="12">
          <h2 class="text-center h2-title">
            Cadastro Eletrônico do Contrato de Prestação de Serviços de Gerenciamento de Campanhas Publicitárias Online
          </h2>
          <p class="mb-0">
            Para iniciar seu processo de Onboarding com a BlinksEssence, basta preencher o documento abaixo com os dados de sua Concessionária Fiat e/ou Jeep.
          </p>
          <p class="mb-0">
            Caso tenha entrado no programa DDM com as duas marcas, é necessário preencher os dados para cada marca neste mesmo formulário, uma vez com os dados da concessionária Fiat, outra com os dados da concessionária Jeep, sem uma ordem certa a ser seguida.
          </p>
          <p class="mb-0">
            Qualquer dúvida no preenchimento dos campos abaixo, é só responder ao e-mail de 'Boas-Vindas' enviado anteriormente, nele também estará disponível o link para o nosso plantão de dúvidas do onboarding, realizado diariamente (Seg-Sex) das 11h às 12h.
          </p>
        </b-col>
      </b-row>
      <div class="min-heigth-53vh">
        <b-form @submit.stop.prevent="submit" v-if="showForm">
          <template>
            <HrSeparator title='Marca' id="hr-brand" />
            <b-row>
              <b-col cols="12">
                <p class="text-center">Selecione a(s) marca(s) da(s) sua(s) concessionária(s).</p>
                <div class="middle">
                  <label>
                    <input type="checkbox" name="brandFiat" :value="true" v-model='fiat.selected' :disabled='updateForm'/>
                    <div class="box">
                      <div id="input-fiat">
                        <img :src="logoFiat" alt="" v-show="fiat.selected" style='margin-top: -20px;'>
                        <span class="d-block" v-if="!fiat.selected">
                          Fiat
                        </span>
                      </div>
                    </div>
                  </label>
                  <label class="ml-1">
                    <input type="checkbox" name="brandJeep" :value='true' v-model='jeep.selected' :disabled='updateForm'>
                    <div class="box">
                      <div id="input-jeep">
                        <img :src="logoJeep" alt="" v-show="jeep.selected" style='margin-top: 10px;'>
                        <span class="d-block" v-if="!jeep.selected">
                          Jeep
                        </span>
                      </div>
                    </div>
                  </label>
                </div>
              </b-col>
            </b-row>
          </template>

          <b-row v-if="fiat.selected">
            <b-col cols="12">
              <b-button
                :class="collapseFiat ? null : 'collapsed'"
                :aria-expanded="collapseFiat ? 'true' : 'false'"
                aria-controls="collapseFiat"
                @click="collapseFiat = !collapseFiat"
                block variant="info"
              >
                Fiat
              </b-button>
              <b-collapse id="collapseFiat" class="mt-2" v-model="collapseFiat">
                <b-card>
                  <template>
                    <HrSeparator title='Lojas' id="fiat-hr-stores" />
                    <b-row>
                      <b-col offset-md="2" md="8" cols="12">
                        <table class="table black-table">
                          <thead class="thead-dark">
                            <tr>
                              <th scope="col">Número de Lojas</th>
                              <th scope="col">Fee Mensal</th>
                              <th scope="col">*Multimarcas</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, index) in fiat.items" :key="`fiat_${index}_${new Date()}_table`" :id="`tr-${index}`" :class="index === fiat.selectedStart ? 'background-gainsboro' : ''">
                              <td>{{ item.storeNumber }}</td>
                              <td>
                                {{ new Intl.NumberFormat('pt-BR', {
                                  style: 'currency',
                                  currency: 'BRL',
                                }).format(item.monthlyFee) }}
                              </td>
                              <td>
                                {{ new Intl.NumberFormat('pt-BR', {
                                  style: 'currency',
                                  currency: 'BRL',
                                }).format(item.monthlyFee * 0.95) }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <b-form-text>
                          *Multimarcas: Dealers que entrarem no programa com ambas as marcas (Fiat e Jeep) possuem um desconto de 5% nos valores de Fee Mensal apresentados.
                        </b-form-text>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-form-group label="Número de Lojas:">
                          <b-form-input
                            name="fiatNumberStores"
                            v-model="fiat.numberStores"
                            type="number"
                            v-validate="{ required: true }"
                            :state="validateState('fiatNumberStores')"
                            data-vv-as="Número de Lojas"
                            placeholder="Digite o Número de Lojas"
                            @focus="addFieldFocus('fiatNumberStores')"
                            @blur="removeFieldFocus('fiatNumberStores')"
                            @change="updateSelectedTable('fiat')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('fiatNumberStores') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('fiatNumberStores') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </template>
                  <template>
                    <HrSeparator title='Dados Cliente' id="fiat-hr-client" />
                    <b-row>
                      <b-col md="6" sm="12">
                        <b-form-group label="Nome Fantasia:">
                          <b-form-input
                            name="fiatFantasyName"
                            v-model="fiat.fantasyName"
                            type="text"
                            v-validate="{ required: true }"
                            :state="validateState('fiatFantasyName')"
                            data-vv-as="Nome Fantasia"
                            placeholder="Digite o Nome Fantasia"
                            @focus="addFieldFocus('fiatFantasyName')"
                            @blur="removeFieldFocus('fiatFantasyName')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('fiatFantasyName') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('fiatFantasyName') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                      <b-col md="6" sm="12">
                        <b-form-group label="Razão Social:">
                          <b-form-input
                            name="fiatCompanyName"
                            v-model="fiat.companyName"
                            type="text"
                            v-validate="{ required: true }"
                            :state="validateState('fiatCompanyName')"
                            data-vv-as="Razão Social"
                            placeholder="Digite a Razão Social"
                            @focus="addFieldFocus('fiatCompanyName')"
                            @blur="removeFieldFocus('fiatCompanyName')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('fiatCompanyName') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('fiatCompanyName') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="6" sm="12">
                        <b-form-group label="CNPJ:">
                          <b-form-input
                            name="fiatCNPJ"
                            v-model="fiat.cNPJ"
                            type="text"
                            v-validate="{ required: true, length: 18 }"
                            :state="validateState('fiatCNPJ') && cnpjValidation(this.fiat.cNPJ)"
                            data-vv-as="CNPJ"
                            v-mask="'##.###.###/####-##'"
                            placeholder="Digite o CPNJ"
                            @focus="addFieldFocus('fiatCNPJ')"
                            @blur="removeFieldFocus('fiatCNPJ')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('fiatCNPJ') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('fiatCNPJ') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                      <b-col md="6" sm="12">
                        <b-form-group label="Ramo de Negócio:">
                          <b-form-input
                            name="fiatBusiness"
                            v-model="fiat.business"
                            type="text"
                            v-validate="{ required: true }"
                            :state="validateState('fiatBusiness')"
                            data-vv-as="Ramo de Negócio"
                            placeholder="Digite o Ramo de Negócio"
                            @focus="addFieldFocus('fiatBusiness')"
                            @blur="removeFieldFocus('fiatBusiness')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('fiatBusiness') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('fiatBusiness') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="6" sm="12">
                        <b-form-group label="Inscrição Municipal:">
                          <b-form-input
                            name="fiatMunicipalRegistration"
                            v-model="fiat.municipalRegistration"
                            type="text"
                            v-validate="{ required: true }"
                            :state="validateState('fiatMunicipalRegistration')"
                            data-vv-as="Inscrição Municipal"
                            placeholder="Digite a Inscrição Municipal"
                            @focus="addFieldFocus('fiatMunicipalRegistration')"
                            @blur="removeFieldFocus('fiatMunicipalRegistration')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('fiatMunicipalRegistration') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('fiatMunicipalRegistration') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                      <b-col md="6" sm="12">
                        <b-form-group label="Inscrição Estadual:">
                          <b-form-input
                            name="fiatStateRegistration"
                            v-model="fiat.stateRegistration"
                            type="text"
                            v-validate="{ required: true }"
                            :state="validateState('fiatStateRegistration')"
                            data-vv-as="Inscrição Estadual"
                            placeholder="Digite a Inscrição Estadual"
                            @focus="addFieldFocus('fiatStateRegistration')"
                            @blur="removeFieldFocus('fiatStateRegistration')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('fiatStateRegistration') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('fiatStateRegistration') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12" sm="12">
                        <b-form-group label="Logradouro:">
                          <b-form-input
                            name="fiatStreet"
                            v-model="fiat.street"
                            type="text"
                            v-validate="{ required: true }"
                            :state="validateState('fiatStreet')"
                            data-vv-as="Logradouro"
                            placeholder="Digite o Logradouro"
                            @focus="addFieldFocus('fiatStreet')"
                            @blur="removeFieldFocus('fiatStreet')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('fiatStreet') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('fiatStreet') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="6" sm="12">
                        <b-form-group label="Número:">
                          <b-form-input
                            name="fiatNumberAddress"
                            v-model="fiat.numberAddress"
                            type="text"
                            v-validate="{ required: true }"
                            :state="validateState('fiatNumberAddress')"
                            data-vv-as="Número"
                            placeholder="Digite o Número"
                            @focus="addFieldFocus('fiatNumberAddress')"
                            @blur="removeFieldFocus('fiatNumberAddress')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('fiatNumberAddress') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('fiatNumberAddress') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                      <b-col md="6" sm="12">
                        <b-form-group label="Complemento:">
                          <b-form-input
                            name="fiatComplement"
                            v-model="fiat.complement"
                            type="text"
                            :state="validateState('fiatComplement')"
                            data-vv-as="Complemento"
                            placeholder="Digite o Complemento"
                            @focus="addFieldFocus('fiatComplement')"
                            @blur="removeFieldFocus('fiatComplement')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('fiatComplement') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('fiatComplement') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="6" sm="12">
                        <b-form-group label="CEP:">
                          <b-form-input
                            name="fiatZipCode"
                            v-model="fiat.zipCode"
                            type="text"
                            v-validate="{ required: true, length: 9 }"
                            :state="validateState('fiatZipCode')"
                            data-vv-as="CEP"
                            v-mask="'#####-###'"
                            placeholder="Digite a CEP"
                            @focus="addFieldFocus('fiatZipCode')"
                            @blur="removeFieldFocus('fiatZipCode')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('fiatZipCode') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('zipCode') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                      <b-col md="6" sm="12">
                        <b-form-group label="Bairro:">
                          <b-form-input
                            name="fiatDistrict"
                            v-model="fiat.district"
                            type="text"
                            v-validate="{ required: true }"
                            :state="validateState('fiatDistrict')"
                            data-vv-as="Bairro"
                            placeholder="Digite o Bairro"
                            @focus="addFieldFocus('fiatDistrict')"
                            @blur="removeFieldFocus('fiatDistrict')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('fiatDistrict') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('fiatDistrict') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="6" sm="12">
                        <b-form-group label="Cidade:">
                          <b-form-input
                            name="fiatCity"
                            v-model="fiat.city"
                            type="text"
                            v-validate="{ required: true }"
                            :state="validateState('fiatCity')"
                            data-vv-as="Cidade"
                            placeholder="Digite a Cidade"
                            @focus="addFieldFocus('fiatCity')"
                            @blur="removeFieldFocus('fiatCity')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('fiatCity') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('fiatCity') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                      <b-col md="6" sm="12">
                        <b-form-group label="Estado:">
                          <b-form-input
                            name="fiatState"
                            v-model="fiat.state"
                            type="text"
                            v-validate="{ required: true }"
                            :state="validateState('fiatState')"
                            data-vv-as="Estado"
                            placeholder="Digite o Estado"
                            @focus="addFieldFocus('fiatState')"
                            @blur="removeFieldFocus('fiatState')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('fiatState') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('fiatState') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-form-group label="URL do Site:">
                          <b-form-input
                            name="fiatWebsite"
                            v-model="fiat.website"
                            type="text"
                            v-validate="{ required: true, url: { require_protocol: true } }"
                            :state="validateState('fiatWebsite')"
                            data-vv-as="URL do Site"
                            placeholder="Digite a URL do Site"
                            @focus="addFieldFocus('fiatWebsite')"
                            @blur="removeFieldFocus('fiatWebsite')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('fiatWebsite') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('fiatWebsite') }}</b-form-text>
                          <b-form-text>Exemplo: https://www.blinksessence.com.br/</b-form-text>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </template>
                  <template>
                    <HrSeparator title='Contato Financeiro' id="fiat-hr-financial" />
                    <b-row>
                      <b-col md="4" sm="12">
                        <b-form-group label="Nome:">
                          <b-form-input
                            name="fiatFinancialContractName"
                            v-model="fiat.financialContractName"
                            type="text"
                            v-validate="{ required: true }"
                            :state="validateState('fiatFinancialContractName')"
                            data-vv-as="Nome"
                            placeholder="Digite o Nome"
                            @focus="addFieldFocus('fiatFinancialContractName')"
                            @blur="removeFieldFocus('fiatFinancialContractName')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('fiatFinancialContractName') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('fiatFinancialContractName') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                      <b-col md="4" sm="12">
                        <b-form-group label="Email:">
                          <b-form-input
                            name="fiatFinancialContractMail"
                            v-model="fiat.financialContractMail"
                            type="email"
                            v-validate="{ required: true, email: true }"
                            :state="validateState('fiatFinancialContractMail')"
                            data-vv-as="Email"
                            placeholder="Digite o Email"
                            @focus="addFieldFocus('fiatFinancialContractMail')"
                            @blur="removeFieldFocus('fiatFinancialContractMail')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('fiatFinancialContractMail') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('fiatFinancialContractMail') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                      <b-col md="4" sm="12">
                        <b-form-group label="Telefone:">
                          <b-form-input
                            name="fiatFinancialContractPhone"
                            v-model="fiat.financialContractPhone"
                            type="text"
                            v-validate="{ required: true, min: 14 }"
                            :state="validateState('fiatFinancialContractPhone')"
                            data-vv-as="Telefone"
                            v-mask="['(##) ####-####', '(##) #####-####']"
                            placeholder="Digite o seu Telefone"
                            @focus="addFieldFocus('fiatFinancialContractPhone')"
                            @blur="removeFieldFocus('fiatFinancialContractPhone')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('fiatFinancialContractPhone') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('fiatFinancialContractPhone') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </template>
                  <template>
                    <HrSeparator title='Contato Representante Legal' id="fiat-hr-legal" />
                    <b-row>
                      <b-col md="4" sm="12">
                        <b-form-group label="Nome:">
                          <b-form-input
                            name="fiatLegalRepresentativeName"
                            v-model="fiat.legalRepresentativeName"
                            type="text"
                            v-validate="{ required: true }"
                            :state="validateState('fiatLegalRepresentativeName')"
                            data-vv-as="Nome"
                            placeholder="Digite o Nome"
                            @focus="addFieldFocus('fiatLegalRepresentativeName')"
                            @blur="removeFieldFocus('fiatLegalRepresentativeName')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('fiatLegalRepresentativeName') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('fiatLegalRepresentativeName') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                      <b-col md="4" sm="12">
                        <b-form-group label="Email:">
                          <b-form-input
                            name="fiatLegalRepresentativeMail"
                            v-model="fiat.legalRepresentativeMail"
                            type="email"
                            v-validate="{ required: true, email: true }"
                            :state="validateState('fiatLegalRepresentativeMail')"
                            data-vv-as="Email"
                            placeholder="Digite o Email"
                            @focus="addFieldFocus('fiatLegalRepresentativeMail')"
                            @blur="removeFieldFocus('fiatLegalRepresentativeMail')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('fiatLegalRepresentativeMail') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('fiatLegalRepresentativeMail') }}</b-form-text>
                          <b-form-text>Este será o email responsável pelo retorno do cadastro deste preenchimento</b-form-text>
                        </b-form-group>
                      </b-col>
                      <b-col md="4" sm="12">
                        <b-form-group label="Telefone:">
                          <b-form-input
                            name="fiatLegalRepresentativePhone"
                            v-model="fiat.legalRepresentativePhone"
                            type="text"
                            v-validate="{ required: true, min: 14 }"
                            :state="validateState('fiatLegalRepresentativePhone')"
                            data-vv-as="Telefone"
                            v-mask="['(##) ####-####', '(##) #####-####']"
                            placeholder="Digite o seu Telefone"
                            @focus="addFieldFocus('fiatLegalRepresentativePhone')"
                            @blur="removeFieldFocus('fiatLegalRepresentativePhone')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('fiatLegalRepresentativePhone') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('fiatLegalRepresentativePhone') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </template>
                  <template>
                    <HrSeparator title='Mídia - Principal Contato para assuntos envolvendo a operação' id="fiat-hr-medium" />
                    <b-row>
                      <b-col md="4" sm="12">
                        <b-form-group label="Nome:">
                          <b-form-input
                            name="fiatMediumContractName"
                            v-model="fiat.mediumContractName"
                            type="text"
                            v-validate="{ required: true }"
                            :state="validateState('fiatMediumContractName')"
                            data-vv-as="Nome"
                            placeholder="Digite o Nome"
                            @focus="addFieldFocus('fiatMediumContractName')"
                            @blur="removeFieldFocus('fiatMediumContractName')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('fiatMediumContractName') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('fiatMediumContractName') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                      <b-col md="4" sm="12">
                        <b-form-group label="Email:">
                          <b-form-input
                            name="fiatMediumContractMail"
                            v-model="fiat.mediumContractMail"
                            type="email"
                            v-validate="{ required: true, email: true }"
                            :state="validateState('fiatMediumContractMail')"
                            data-vv-as="Email"
                            placeholder="Digite o Email"
                            @focus="addFieldFocus('fiatMediumContractMail')"
                            @blur="removeFieldFocus('fiatMediumContractMail')"
                          ></b-form-input>
                          <b-form-invalid-feedback id="error-email">{{ getError('fiatMediumContractMail') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('fiatMediumContractMail') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                      <b-col md="4" sm="12">
                        <b-form-group label="Telefone:">
                          <b-form-input
                            name="fiatMediumContractPhone"
                            v-model="fiat.mediumContractPhone"
                            type="text"
                            v-validate="{ required: true, min: 14 }"
                            :state="validateState('fiatMediumContractPhone')"
                            data-vv-as="Telefone"
                            v-mask="['(##) ####-####', '(##) #####-####']"
                            placeholder="Digite o seu Telefone"
                            @focus="addFieldFocus('fiatMediumContractPhone')"
                            @blur="removeFieldFocus('fiatMediumContractPhone')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('fiatMediumContractPhone') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('fiatMediumContractPhone') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </template>
                  <template>
                    <HrSeparator title='Contrato Social' id="fiat-hr-contract" />
                    <b-row>
                      <b-col cols="12">
                        <b-form-group
                          label="Contrato social:"
                          description="Arquivos aceitos: .jpg, .jpeg, .doc, .docx, .pdf, .xls, .xlsx, .png"
                        >
                          <div v-if="!upload && errors.socialContract != null && !errors.socialContract.selected">
                            <b-button 
                              v-b-tooltip.hover title="Download"
                              @click.stop.prevent="getSocialContract('fiat')" 
                              variant="secondary"
                              class="mr-2">
                              <i class="fa fa-download"></i>
                            </b-button>
                            <b-button 
                              v-b-tooltip.hover title="Remover Arquivo"
                              @click.stop.prevent="upload = true" 
                              variant="danger">
                              <i class="fa fa-times"></i>
                            </b-button>
                          </div>
                          <b-form-file 
                            v-else
                            name="fiatSocialContract"
                            v-model="fiat.socialContract"
                            v-validate="{ required: true }"
                            :state="validateState('fiatSocialContract')"
                            placeholder="Escolha os arquivos ou arraste-os aqui"
                            data-vv-as="Contrato social"
                            accept=".jpg, .jpeg, .doc, .docx, .pdf, .xls, .xlsx, .png"
                            description="Arquivos aceitos: .jpg, .jpeg, .doc, .docx, .pdf, .xls, .xlsx, .png"
                            @change="removeFieldFocus('fiatSocialContract')"
                          ></b-form-file>
                          <b-form-invalid-feedback>{{ getError('fiatSocialContract') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('fiatSocialContract') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </template>
                  <template>
                    <HrSeparator title='Cotrato de Prestação de Serviços' id="fiat-hr-comercial" />
                    <b-row>
                      <b-col cols="12">
                        <b-button 
                          v-b-tooltip.hover title="Download do Contrato"
                          @click.stop.prevent="getTerm()" 
                          variant="info"
                          class="mr-2">
                          <i class="fa fa-file-pdf-o"></i>
                        </b-button>
                        <span>
                          Contrato de Prestação de Serviços de Gerenciamento de Campanhas Publicitárias Online
                        </span>
                        <b-form-text>
                          O contrato de prestação de serviços acima foi revisado e validado pelos Jurídicos da Stellantis e das associações de concessionárias (ABRACAF e ABRADIC) e não pode ser modificado.
                        </b-form-text>
                        <b-form-text>
                          Em caso de dúvidas, responda ao e-mail de 'Boas-Vindas' enviado anteriormente ou procure o time Stellantis diretamente.
                        </b-form-text>
                      </b-col>
                      <b-col cols="12" class="mt-2">
                        <b-form-checkbox
                          name="fiatTerms"
                          v-model="fiat.terms"
                          :value="true"
                          :unchecked-value="false"
                        >
                          Eu, {{ fiat.legalRepresentativeName }}, informo que li o contrato de prestação de serviços acima e estou de acordo com as cláusulas comerciais estabelecidas.
                        </b-form-checkbox>
                      </b-col>
                    </b-row>
                  </template>
                </b-card>
              </b-collapse>
            </b-col>
          </b-row>

          <b-row v-if="jeep.selected" class="mt-3">
            <b-col cols="12">
              <b-button
                :class="collapseJeep ? null : 'collapsed'"
                :aria-expanded="collapseJeep ? 'true' : 'false'"
                aria-controls="collapseJeep"
                @click="collapseJeep = !collapseJeep"
                block variant="info"
              >
                Jeep
              </b-button>
              <b-collapse id="collapseJeep" class="mt-2" v-model="collapseJeep">
                <b-card>
                  <template>
                    <HrSeparator title='Lojas' id="jeep-hr-stores" />
                    <b-row>
                      <b-col offset-md="2" md="8" cols="12">
                        <table class="table black-table">
                          <thead class="thead-dark">
                            <tr>
                              <th scope="col">Número de Lojas</th>
                              <th scope="col">Fee Mensal</th>
                              <th scope="col">*Multimarcas</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, index) in jeep.items.filter(x => x.model == jeep.model)" :key="`jeep_${index}_${new Date()}_table`" :id="`tr-${index}`" :class="index === jeep.selectedStart ? 'background-gainsboro' : ''">
                              <td>{{ item.storeNumber }}</td>
                              <td>
                                {{ new Intl.NumberFormat('pt-BR', {
                                  style: 'currency',
                                  currency: 'BRL',
                                }).format(item.monthlyFee) }}
                              </td>
                              <td>
                                {{ new Intl.NumberFormat('pt-BR', {
                                  style: 'currency',
                                  currency: 'BRL',
                                }).format(item.monthlyFee * 0.95) }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <b-form-text>
                          *Multimarcas: Dealers que entrarem no programa com ambas as marcas (jeep e Jeep) possuem um desconto de 5% nos valores de Fee Mensal apresentados.
                        </b-form-text>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-form-group label="Modelos:">
                          <b-form-radio 
                            name="jeepModel"
                            v-model="jeep.model"
                            :state="validateState('jeepModel')"
                            data-vv-as="Modelo"
                            value="2"
                          >
                            2 Modelos
                          </b-form-radio>
                          <b-form-radio 
                            name="jeepModel"
                            v-model="jeep.model"
                            :state="validateState('jeepModel')"
                            data-vv-as="Modelo"
                            value="3"
                          >
                            3 Modelos
                          </b-form-radio>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-form-group label="Número de Lojas:">
                          <b-form-input
                            name="jeepNumberStores"
                            v-model="jeep.numberStores"
                            type="number"
                            v-validate="{ required: true }"
                            :state="validateState('jeepNumberStores')"
                            data-vv-as="Número de Lojas"
                            placeholder="Digite o Número de Lojas"
                            @focus="addFieldFocus('jeepNumberStores')"
                            @blur="removeFieldFocus('jeepNumberStores')"
                            @change="updateSelectedTable('jeep')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('jeepNumberStores') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('jeepNumberStores') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </template>
                  <template>
                    <HrSeparator title='Dados Cliente' id="jeep-hr-client" />
                    <b-row>
                      <b-col md="6" sm="12">
                        <b-form-group label="Nome Fantasia:">
                          <b-form-input
                            name="jeepFantasyName"
                            v-model="jeep.fantasyName"
                            type="text"
                            v-validate="{ required: true }"
                            :state="validateState('jeepFantasyName')"
                            data-vv-as="Nome Fantasia"
                            placeholder="Digite o Nome Fantasia"
                            @focus="addFieldFocus('jeepFantasyName')"
                            @blur="removeFieldFocus('jeepFantasyName')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('jeepFantasyName') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('jeepFantasyName') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                      <b-col md="6" sm="12">
                        <b-form-group label="Razão Social:">
                          <b-form-input
                            name="jeepCompanyName"
                            v-model="jeep.companyName"
                            type="text"
                            v-validate="{ required: true }"
                            :state="validateState('jeepCompanyName')"
                            data-vv-as="Razão Social"
                            placeholder="Digite a Razão Social"
                            @focus="addFieldFocus('jeepCompanyName')"
                            @blur="removeFieldFocus('jeepCompanyName')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('jeepCompanyName') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('jeepCompanyName') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="6" sm="12">
                        <b-form-group label="CNPJ:">
                          <b-form-input
                            name="jeepCNPJ"
                            v-model="jeep.cNPJ"
                            type="text"
                            v-validate="{ required: true, length: 18 }"
                            :state="validateState('jeepCNPJ') && cnpjValidation(this.jeep.cNPJ)"
                            data-vv-as="CNPJ"
                            v-mask="'##.###.###/####-##'"
                            placeholder="Digite o CPNJ"
                            @focus="addFieldFocus('jeepCNPJ')"
                            @blur="removeFieldFocus('jeepCNPJ')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('jeepCNPJ') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('jeepCNPJ') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                      <b-col md="6" sm="12">
                        <b-form-group label="Ramo de Negócio:">
                          <b-form-input
                            name="jeepBusiness"
                            v-model="jeep.business"
                            type="text"
                            v-validate="{ required: true }"
                            :state="validateState('jeepBusiness')"
                            data-vv-as="Ramo de Negócio"
                            placeholder="Digite o Ramo de Negócio"
                            @focus="addFieldFocus('jeepBusiness')"
                            @blur="removeFieldFocus('jeepBusiness')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('jeepBusiness') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('jeepBusiness') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="6" sm="12">
                        <b-form-group label="Inscrição Municipal:">
                          <b-form-input
                            name="jeepMunicipalRegistration"
                            v-model="jeep.municipalRegistration"
                            type="text"
                            v-validate="{ required: true }"
                            :state="validateState('jeepMunicipalRegistration')"
                            data-vv-as="Inscrição Municipal"
                            placeholder="Digite a Inscrição Municipal"
                            @focus="addFieldFocus('jeepMunicipalRegistration')"
                            @blur="removeFieldFocus('jeepMunicipalRegistration')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('jeepMunicipalRegistration') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('jeepMunicipalRegistration') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                      <b-col md="6" sm="12">
                        <b-form-group label="Inscrição Estadual:">
                          <b-form-input
                            name="jeepStateRegistration"
                            v-model="jeep.stateRegistration"
                            type="text"
                            v-validate="{ required: true }"
                            :state="validateState('jeepStateRegistration')"
                            data-vv-as="Inscrição Estadual"
                            placeholder="Digite a Inscrição Estadual"
                            @focus="addFieldFocus('jeepStateRegistration')"
                            @blur="removeFieldFocus('jeepStateRegistration')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('jeepStateRegistration') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('jeepStateRegistration') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12" sm="12">
                        <b-form-group label="Logradouro:">
                          <b-form-input
                            name="jeepStreet"
                            v-model="jeep.street"
                            type="text"
                            v-validate="{ required: true }"
                            :state="validateState('jeepStreet')"
                            data-vv-as="Logradouro"
                            placeholder="Digite o Logradouro"
                            @focus="addFieldFocus('jeepStreet')"
                            @blur="removeFieldFocus('jeepStreet')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('jeepStreet') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('jeepStreet') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="6" sm="12">
                        <b-form-group label="Número:">
                          <b-form-input
                            name="jeepNumberAddress"
                            v-model="jeep.numberAddress"
                            type="text"
                            v-validate="{ required: true }"
                            :state="validateState('jeepNumberAddress')"
                            data-vv-as="Número"
                            placeholder="Digite o Número"
                            @focus="addFieldFocus('jeepNumberAddress')"
                            @blur="removeFieldFocus('jeepNumberAddress')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('jeepNumberAddress') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('jeepNumberAddress') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                      <b-col md="6" sm="12">
                        <b-form-group label="Complemento:">
                          <b-form-input
                            name="jeepComplement"
                            v-model="jeep.complement"
                            type="text"
                            :state="validateState('jeepComplement')"
                            data-vv-as="Complemento"
                            placeholder="Digite o Complemento"
                            @focus="addFieldFocus('jeepComplement')"
                            @blur="removeFieldFocus('jeepComplement')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('jeepComplement') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('jeepComplement') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="6" sm="12">
                        <b-form-group label="CEP:">
                          <b-form-input
                            name="jeepZipCode"
                            v-model="jeep.zipCode"
                            type="text"
                            v-validate="{ required: true, length: 9 }"
                            :state="validateState('jeepZipCode')"
                            data-vv-as="CEP"
                            v-mask="'#####-###'"
                            placeholder="Digite a CEP"
                            @focus="addFieldFocus('jeepZipCode')"
                            @blur="removeFieldFocus('jeepZipCode')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('jeepZipCode') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('zipCode') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                      <b-col md="6" sm="12">
                        <b-form-group label="Bairro:">
                          <b-form-input
                            name="jeepDistrict"
                            v-model="jeep.district"
                            type="text"
                            v-validate="{ required: true }"
                            :state="validateState('jeepDistrict')"
                            data-vv-as="Bairro"
                            placeholder="Digite o Bairro"
                            @focus="addFieldFocus('jeepDistrict')"
                            @blur="removeFieldFocus('jeepDistrict')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('jeepDistrict') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('jeepDistrict') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="6" sm="12">
                        <b-form-group label="Cidade:">
                          <b-form-input
                            name="jeepCity"
                            v-model="jeep.city"
                            type="text"
                            v-validate="{ required: true }"
                            :state="validateState('jeepCity')"
                            data-vv-as="Cidade"
                            placeholder="Digite a Cidade"
                            @focus="addFieldFocus('jeepCity')"
                            @blur="removeFieldFocus('jeepCity')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('jeepCity') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('jeepCity') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                      <b-col md="6" sm="12">
                        <b-form-group label="Estado:">
                          <b-form-input
                            name="jeepState"
                            v-model="jeep.state"
                            type="text"
                            v-validate="{ required: true }"
                            :state="validateState('jeepState')"
                            data-vv-as="Estado"
                            placeholder="Digite o Estado"
                            @focus="addFieldFocus('jeepState')"
                            @blur="removeFieldFocus('jeepState')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('jeepState') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('jeepState') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12">
                        <b-form-group label="URL do Site:">
                          <b-form-input
                            name="jeepWebsite"
                            v-model="jeep.website"
                            type="text"
                            v-validate="{ required: true, url: { require_protocol: true } }"
                            :state="validateState('jeepWebsite')"
                            data-vv-as="URL do Site"
                            placeholder="Digite a URL do Site"
                            @focus="addFieldFocus('jeepWebsite')"
                            @blur="removeFieldFocus('jeepWebsite')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('jeepWebsite') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('jeepWebsite') }}</b-form-text>
                          <b-form-text>Exemplo: https://www.blinksessence.com.br/</b-form-text>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </template>
                  <template>
                    <HrSeparator title='Contato Financeiro' id="jeep-hr-financial" />
                    <b-row>
                      <b-col md="4" sm="12">
                        <b-form-group label="Nome:">
                          <b-form-input
                            name="jeepFinancialContractName"
                            v-model="jeep.financialContractName"
                            type="text"
                            v-validate="{ required: true }"
                            :state="validateState('jeepFinancialContractName')"
                            data-vv-as="Nome"
                            placeholder="Digite o Nome"
                            @focus="addFieldFocus('jeepFinancialContractName')"
                            @blur="removeFieldFocus('jeepFinancialContractName')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('jeepFinancialContractName') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('jeepFinancialContractName') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                      <b-col md="4" sm="12">
                        <b-form-group label="Email:">
                          <b-form-input
                            name="jeepFinancialContractMail"
                            v-model="jeep.financialContractMail"
                            type="email"
                            v-validate="{ required: true, email: true }"
                            :state="validateState('jeepFinancialContractMail')"
                            data-vv-as="Email"
                            placeholder="Digite o Email"
                            @focus="addFieldFocus('jeepFinancialContractMail')"
                            @blur="removeFieldFocus('jeepFinancialContractMail')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('jeepFinancialContractMail') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('jeepFinancialContractMail') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                      <b-col md="4" sm="12">
                        <b-form-group label="Telefone:">
                          <b-form-input
                            name="jeepFinancialContractPhone"
                            v-model="jeep.financialContractPhone"
                            type="text"
                            v-validate="{ required: true, min: 14 }"
                            :state="validateState('jeepFinancialContractPhone')"
                            data-vv-as="Telefone"
                            v-mask="['(##) ####-####', '(##) #####-####']"
                            placeholder="Digite o seu Telefone"
                            @focus="addFieldFocus('jeepFinancialContractPhone')"
                            @blur="removeFieldFocus('jeepFinancialContractPhone')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('jeepFinancialContractPhone') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('jeepFinancialContractPhone') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </template>
                  <template>
                    <HrSeparator title='Contato Representante Legal' id="jeep-hr-legal" />
                    <b-row>
                      <b-col md="4" sm="12">
                        <b-form-group label="Nome:">
                          <b-form-input
                            name="jeepLegalRepresentativeName"
                            v-model="jeep.legalRepresentativeName"
                            type="text"
                            v-validate="{ required: true }"
                            :state="validateState('jeepLegalRepresentativeName')"
                            data-vv-as="Nome"
                            placeholder="Digite o Nome"
                            @focus="addFieldFocus('jeepLegalRepresentativeName')"
                            @blur="removeFieldFocus('jeepLegalRepresentativeName')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('jeepLegalRepresentativeName') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('jeepLegalRepresentativeName') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                      <b-col md="4" sm="12">
                        <b-form-group label="Email:">
                          <b-form-input
                            name="jeepLegalRepresentativeMail"
                            v-model="jeep.legalRepresentativeMail"
                            type="email"
                            v-validate="{ required: true, email: true }"
                            :state="validateState('jeepLegalRepresentativeMail')"
                            data-vv-as="Email"
                            placeholder="Digite o Email"
                            @focus="addFieldFocus('jeepLegalRepresentativeMail')"
                            @blur="removeFieldFocus('jeepLegalRepresentativeMail')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('jeepLegalRepresentativeMail') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('jeepLegalRepresentativeMail') }}</b-form-text>
                          <b-form-text>Este será o email responsável pelo retorno do cadastro deste preenchimento</b-form-text>
                        </b-form-group>
                      </b-col>
                      <b-col md="4" sm="12">
                        <b-form-group label="Telefone:">
                          <b-form-input
                            name="jeepLegalRepresentativePhone"
                            v-model="jeep.legalRepresentativePhone"
                            type="text"
                            v-validate="{ required: true, min: 14 }"
                            :state="validateState('jeepLegalRepresentativePhone')"
                            data-vv-as="Telefone"
                            v-mask="['(##) ####-####', '(##) #####-####']"
                            placeholder="Digite o seu Telefone"
                            @focus="addFieldFocus('jeepLegalRepresentativePhone')"
                            @blur="removeFieldFocus('jeepLegalRepresentativePhone')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('jeepLegalRepresentativePhone') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('jeepLegalRepresentativePhone') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </template>
                  <template>
                    <HrSeparator title='Mídia - Principal Contato para assuntos envolvendo a operação' id="jeep-hr-medium" />
                    <b-row>
                      <b-col md="4" sm="12">
                        <b-form-group label="Nome:">
                          <b-form-input
                            name="jeepMediumContractName"
                            v-model="jeep.mediumContractName"
                            type="text"
                            v-validate="{ required: true }"
                            :state="validateState('jeepMediumContractName')"
                            data-vv-as="Nome"
                            placeholder="Digite o Nome"
                            @focus="addFieldFocus('jeepMediumContractName')"
                            @blur="removeFieldFocus('jeepMediumContractName')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('jeepMediumContractName') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('jeepMediumContractName') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                      <b-col md="4" sm="12">
                        <b-form-group label="Email:">
                          <b-form-input
                            name="jeepMediumContractMail"
                            v-model="jeep.mediumContractMail"
                            type="email"
                            v-validate="{ required: true, email: true }"
                            :state="validateState('jeepMediumContractMail')"
                            data-vv-as="Email"
                            placeholder="Digite o Email"
                            @focus="addFieldFocus('jeepMediumContractMail')"
                            @blur="removeFieldFocus('jeepMediumContractMail')"
                          ></b-form-input>
                          <b-form-invalid-feedback id="error-email">{{ getError('jeepMediumContractMail') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('jeepMediumContractMail') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                      <b-col md="4" sm="12">
                        <b-form-group label="Telefone:">
                          <b-form-input
                            name="jeepMediumContractPhone"
                            v-model="jeep.mediumContractPhone"
                            type="text"
                            v-validate="{ required: true, min: 14 }"
                            :state="validateState('jeepMediumContractPhone')"
                            data-vv-as="Telefone"
                            v-mask="['(##) ####-####', '(##) #####-####']"
                            placeholder="Digite o seu Telefone"
                            @focus="addFieldFocus('jeepMediumContractPhone')"
                            @blur="removeFieldFocus('jeepMediumContractPhone')"
                          ></b-form-input>
                          <b-form-invalid-feedback>{{ getError('jeepMediumContractPhone') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('jeepMediumContractPhone') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </template>
                  <template>
                    <HrSeparator title='Contrato Social' id="jeep-hr-contract" />
                    <b-row>
                      <b-col cols="12">
                        <b-form-group
                          label="Contrato social:"
                          description="Arquivos aceitos: .jpg, .jpeg, .doc, .docx, .pdf, .xls, .xlsx, .png"
                        >
                          <div v-if="!upload && errors.socialContract != null && !errors.socialContract.selected">
                            <b-button 
                              v-b-tooltip.hover title="Download"
                              @click.stop.prevent="getSocialContract('jeep')" 
                              variant="secondary"
                              class="mr-2">
                              <i class="fa fa-download"></i>
                            </b-button>
                            <b-button 
                              v-b-tooltip.hover title="Remover Arquivo"
                              @click.stop.prevent="upload = true" 
                              variant="danger">
                              <i class="fa fa-times"></i>
                            </b-button>
                          </div>
                          <b-form-file 
                            v-else
                            name="jeepSocialContract"
                            v-model="jeep.socialContract"
                            v-validate="{ required: true }"
                            :state="validateState('jeepSocialContract')"
                            placeholder="Escolha os arquivos ou arraste-os aqui"
                            data-vv-as="Contrato social"
                            accept=".jpg, .jpeg, .doc, .docx, .pdf, .xls, .xlsx, .png"
                            description="Arquivos aceitos: .jpg, .jpeg, .doc, .docx, .pdf, .xls, .xlsx, .png"
                            @change="removeFieldFocus('jeepSocialContract')"
                          ></b-form-file>
                          <b-form-invalid-feedback>{{ getError('jeepSocialContract') }}</b-form-invalid-feedback>
                          <b-form-text>{{ getErrorDescription('jeepSocialContract') }}</b-form-text>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </template>
                  <template>
                    <HrSeparator title='Cotrato de Prestação de Serviços' id="jeep-hr-comercial" />
                    <b-row>
                      <b-col cols="12">
                        <b-button 
                          v-b-tooltip.hover title="Download do Contrato"
                          @click.stop.prevent="getTerm()" 
                          variant="info"
                          class="mr-2">
                          <i class="fa fa-file-pdf-o"></i>
                        </b-button>
                        <span>
                          Contrato de Prestação de Serviços de Gerenciamento de Campanhas Publicitárias Online
                        </span>
                        <b-form-text>
                          O contrato de prestação de serviços acima foi revisado e validado pelos Jurídicos da Stellantis e das associações de concessionárias (ABRACAF e ABRADIC) e não pode ser modificado.
                        </b-form-text>
                        <b-form-text>
                          Em caso de dúvidas, responda ao e-mail de 'Boas-Vindas' enviado anteriormente ou procure o time Stellantis diretamente.
                        </b-form-text>
                      </b-col>
                      <b-col cols="12" class="mt-2">
                        <b-form-checkbox
                          name="jeepTerms"
                          v-model="jeep.terms"
                          :value="true"
                          :unchecked-value="false"
                        >
                          Eu, {{ jeep.legalRepresentativeName }}, informo que li o contrato de prestação de serviços acima e estou de acordo com as cláusulas comerciais estabelecidas.
                        </b-form-checkbox>
                      </b-col>
                    </b-row>
                  </template>
                </b-card>
              </b-collapse>
            </b-col>
          </b-row>
          <b-button variant="info" class="d-block mx-auto mt-3" type="submit" v-if="fiat.selected || jeep.selected">
            Enviar
          </b-button>
        </b-form>
      </div>
    </main>
    <Footer />
    <div class="spinner" v-if="spinner">
      <Spinner class="spinner-item" />
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import Spinner from '../components/Spinner'
import Header from '../components/Header'
import Footer from '../components/Footer'
import HrSeparator from '../components/HrSeparator'
import FormService from '../service/FormService'
import { mask } from 'vue-the-mask'

export default {
  components: { Spinner, HrSeparator, Header, Footer },
  directives: { mask },
  data() {
    return {
      collapseFiat: false,
      collapseJeep: false,
      logoJeep: require('@/assets/images/logo-jeep-white-100.png'),
      logoFiat: require('@/assets/images/logo-fiat-white-100.png'),
      fiat: {
        brand: 'Fiat',
        selected: false,
        principalId: null,
        id: null,
        fantasyName: '',
        companyName: '',
        cNPJ: '',
        business: '',
        municipalRegistration: '',
        stateRegistration: '',
        street: '',
        numberAddress: '',
        complement: '',
        zipCode: '',
        district: '',
        city: '',
        state: '',
        website: '',
        financialContractName: '',
        financialContractMail: '',
        financialContractPhone: '',
        legalRepresentativeName: '',
        legalRepresentativeMail: '',
        legalRepresentativePhone: '',
        mediumContractName: '',
        mediumContractMail: '',
        mediumContractPhone: '',
        numberStores: '',
        monthlyFee: '',
        socialContract: null,
        fileNameSocialContract: null,
        terms: false,
        items: [],
        upload: false,
        selectedStart: -1,
        model: 4
      },
      jeep: {
        brand: 'Jeep',
        selected: false,
        principalId: null,
        id: null,
        fantasyName: '',
        companyName: '',
        cNPJ: '',
        business: '',
        municipalRegistration: '',
        stateRegistration: '',
        street: '',
        numberAddress: '',
        complement: '',
        zipCode: '',
        district: '',
        city: '',
        state: '',
        website: '',
        financialContractName: '',
        financialContractMail: '',
        financialContractPhone: '',
        legalRepresentativeName: '',
        legalRepresentativeMail: '',
        legalRepresentativePhone: '',
        mediumContractName: '',
        mediumContractMail: '',
        mediumContractPhone: '',
        numberStores: '',
        monthlyFee: '',
        socialContract: null,
        fileNameSocialContract: null,
        terms: false,
        items: [],
        selectedStart: -1,
        model: 2
      },
      upload: true,
      updateForm: false,
      errors: {},
      infos: [],
      link: '',
      urlTerm: '',
      spinner: false,
      service: null,
      fieldFocus: [],
      showForm: true,
      isMobile: false
    }
  },
  methods: {
    cnpjValidation(value) {
      if (!value) return false

      // Aceita receber o valor como string, número ou array com todos os dígitos
      const validTypes =
        typeof value === 'string' || Number.isInteger(value) || Array.isArray(value)

      // Elimina valor em formato inválido
      if (!validTypes) return false

      // Guarda um array com todos os dígitos do valor
      const match = value.toString().match(/\d/g)
      const numbers = Array.isArray(match) ? match.map(Number) : []

      // Valida a quantidade de dígitos
      if (numbers.length !== 14) return false
      
      // Elimina inválidos com todos os dígitos iguais
      const items = [...new Set(numbers)]
      if (items.length === 1) return false

      // Cálculo validador
      const calc = (x) => {
        const slice = numbers.slice(0, x)
        let factor = x - 7
        let sum = 0

        for (let i = x; i >= 1; i--) {
          const n = slice[x - i]
          sum += n * factor--
          if (factor < 2) factor = 9
        }

        const result = 11 - (sum % 11)

        return result > 9 ? 0 : result
      }

      // Separa os 2 últimos dígitos de verificadores
      const digits = numbers.slice(12)
      
      // Valida 1o. dígito verificador
      const digit0 = calc(12)
      if (digit0 !== digits[0]) return false

      // Valida 2o. dígito verificador
      const digit1 = calc(13)
      return digit1 === digits[1]
    },
    addFieldFocus (field) {
      this.fieldFocus.push(field)
    },
    removeFieldFocus (field) {
      this.fieldFocus = this.fieldFocus.filter(x => x !== field)
    },
    validateState(field) {
      let newField = this.removeBrandName(field)
      if (this.errors === null || this.errors === undefined) {
        return null
      }

      if (this.fieldFocus.some(x => this.removeBrandName(x) === newField) && this.errors[newField] !== undefined) {
        return !this.errors[newField].selected
      }
      if (this.errors[newField] !== undefined && this.fieldFocus.some(x => this.removeBrandName(x) === newField)) {
        return !this.errors[newField].selected
      }
      if (this.veeFields[newField] &&(this.veeFields[newField].dirty || this.veeFields[newField].validated)) {
        return !this.veeErrors.has(newField);
      }

      if (this.fieldFocus.some(x => x === field) && this.errors[field] !== undefined) {
        return !this.errors[field].selected
      }
      if (this.errors[field] !== undefined && this.fieldFocus.some(x => x === field)) {
        return !this.errors[field].selected
      }
      if (this.veeFields[field] &&(this.veeFields[field].dirty || this.veeFields[field].validated)) {
        return !this.veeErrors.has(field);
      }

      return null;
    },
    getErrorDescription (field) {
      let newField = this.removeBrandName(field)
      if (this.errors === null || this.errors === undefined) {
        return ''
      }

      if (this.errors[field] != undefined) {
        if (this.errors[field].selected && !(this.fieldFocus.some(x => x === field))) {
          return this.errors[field].text
        }
      }

      if (this.errors[newField] != undefined) {
        if (this.errors[newField].selected && !(this.fieldFocus.some(x => this.removeBrandName(x) === newField))) {
          return this.errors[newField].text
        }
      }
      return ''
    },
    getError (field) {
      let newField = this.removeBrandName(field)
      if (this.errors === null || this.errors === undefined) {
        return ''
      }

      if (this.errors[field] != undefined && field) {
        if (this.fieldFocus.some(x => x === field) &&  this.errors[field].selected) {
          return this.errors[field].text
        } else if (this.fieldFocus.some(x => x === field)) {
          return this.errors[field].text
        }
      } 
      if (this.errors[newField] != undefined && newField) {
        if (this.fieldFocus.some(x => this.removeBrandName(x) === newField) &&  this.errors[newField].selected) {
          return this.errors[newField].text
        } else if (this.fieldFocus.some(x => this.removeBrandName(x) === newField)) {
          return this.errors[newField].text
        }
      }
      if (newField === 'cNPJ') {
        if (this.fiat.selected && !this.cnpjValidation(this.fiat.cNPJ))
          return 'O campo CNPJ deve ser válido'
        else if (this.jeep.selected && !this.cnpjValidation(this.jeep.cNPJ))
          return 'O campo CNPJ deve ser válido'
      }
      let result = this.veeErrors.first(field) !== undefined ? this.veeErrors.first(field) : this.veeErrors.first(newField)
      return result
    },
    updateSelectedTable (brand) {
      if (brand === 'fiat') {
        const numberStores = parseInt(this.fiat.numberStores)
        
        if (isNaN(numberStores)) {
          this.fiat.selectedStart = -1
        } else if (numberStores <= 3) {
          this.fiat.selectedStart = 0
        } else if (numberStores <= 6) {
          this.fiat.selectedStart = 1
        } else if (numberStores <= 9) {
          this.fiat.selectedStart = 2
        } else {
          this.fiat.selectedStart = 3
        }
      } else {
        const numberStores = parseInt(this.jeep.numberStores)
        
        if (isNaN(numberStores)) {
          this.jeep.selectedStart = -1
        } else if (numberStores <= 3) {
          this.jeep.selectedStart = 0
        } else if (numberStores <= 6) {
          this.jeep.selectedStart = 1
        } else if (numberStores <= 9) {
          this.jeep.selectedStart = 2
        } else {
          this.jeep.selectedStart = 3
        }
      }
    },
    getByLink (user) {
      this.spinner = true
      this.service.getByLink(user)
        .then(response => {
          let brand = ''
          this.spinner = false
          if (response.status.value === 0) {
            if (response.data.object.brand === 'Fiat') {
              brand = 'fiat'
              let items = this.fiat.items
              this.fiat = response.data.object
              this.fiat.cNPJ = response.data.object.cnpj
              this.fiat.selected = true
              this.fiat.items = items
            } else {
              let items = this.jeep.items
              brand = 'jeep'
              this.jeep = response.data.object
              this.jeep.cNPJ = response.data.object.cnpj
              this.jeep.selected = true
              this.jeep.items = items
            }
            this.updateSelectedTable(brand)
            const errors = JSON.parse(response.data.object.disapprovalNote)
            this.errors = errors

            this.upload = false

            if (response.data.object.statusId !== 4) {
              this.showForm = false
              this.spinner = false
              Swal.fire({
                icon: 'info',
                title: 'Atenção',
                text: 'Este link já foi aprovado',
                showConfirmButton: false,
                allowOutsideClick: false
              })
              return
            } else {
              for (let [key, value] of Object.entries(errors)) {
                if (value.selected) {
                  if (key === 'socialContract') {
                    this.upload = true
                  }
                  key = key.charAt(0).toUpperCase() + key.slice(1);
                  this.addFieldFocus(`${brand}${key}`)
                }
              }
            }
            
          } else if (response.status.value === 10) {
            Swal.fire({
              icon: 'info',
              title: 'Url Inválida',
              text: 'Clique OK para continuar',
              allowOutsideClick: false,
              preConfirm: () => {
                window.location = window.location.origin
              }
            })
          } else if (response.status.value === 20) {
            Swal.fire({
              icon: 'info',
              title: 'Atualização já efetuada',
              text: 'Clique OK para voltar ao formulário',
              allowOutsideClick: false,
              preConfirm: () => {
                window.location = window.location.origin
              }
            })
          } else {
            Swal.fire({
              icon: 'info',
              title: 'Link Expirado',
              text: 'Este link foi expirado, para receber um novo link no seu email, clique no botão abaixo!',
              confirmButtonText: 'Reenviar Link',
              allowOutsideClick: false,
              preConfirm: () => {
                this.sendLink(response.data.object.formDealerId)
                  .then((res) => {
                    if (res.status.value !== 11) {
                      Swal.fire({
                        icon: 'info',
                        title: 'Um novo link foi gerado',
                        text: 'Verifique seu email',
                        showConfirmButton: false,
                        allowOutsideClick: false
                      })
                    } else {
                      Swal.fire({
                        icon: 'info',
                        title: 'Erro',
                        text: 'Ocorreu um erro ao reenviar o link.',
                        allowOutsideClick: false,
                        showConfirmButton: false
                      })
                    }
                  })
                  .catch(error => {
                    console.log(error)
                  })
              },
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    sendLink (id) {
      return this.service.generateLink(id)
    },
    submit () {
      this.$validator.validateAll().then(result => {
        if (!result || this.fieldFocus.length !== 0) {
          let idElement = ''
          if (this.fiat.selected) {
            if (this.collapseFiat) {
              if (this.getError('fiatNumberStores')) {
                idElement = 'fiat-hr-stores'
              } else if (this.getError('fiatFantasyName') !== undefined || this.getError('fiatCompanyName') !== undefined || this.getError('fiatCNPJ') !== undefined || this.getError('fiatBusiness') !== undefined || this.getError('fiatMunicipalRegistration') !== undefined || this.getError('fiatStateRegistration') !== undefined || this.getError('fiatStreet') !== undefined || this.getError('fiatNumberAddress') !== undefined || this.getError('fiatComplement') !== undefined || this.getError('fiatZipCode') !== undefined || this.getError('fiatDistrict') !== undefined || this.getError('fiatCity') !== undefined || this.getError('fiatState') !== undefined || this.getError('fiatWebsite') !== undefined) {
                idElement = 'fiat-hr-client'
              } else if (this.getError('fiatFinancialContractName') !== undefined || this.getError('fiatFinancialContractMail') !== undefined || this.getError('fiatFinancialContractPhone') !== undefined) {
                idElement = 'fiat-hr-financial'
              } else if (this.getError('fiatLegalRepresentativeName') !== undefined || this.getError('fiatLegalRepresentativeMail') !== undefined || this.getError('fiatLegalRepresentativePhone') !== undefined) {
                idElement = 'fiat-hr-legal'
              } else if (this.getError('fiatMediumContractName') !== undefined || this.getError('fiatMediumContractMail') !== undefined || this.getError('fiatMediumContractPhone') !== undefined) {
                idElement = 'fiat-hr-medium'
              }
            } else {
              idElement = 'hr-brand'
            }
            this.collapseFiat = true
          } else if (this.jeep.selected) {
            if (this.collapseJeep) {
              if (this.getError('jeepNumberStores')) {
                idElement = 'jeep-hr-stores'
              } else if (this.getError('jeepFantasyName') !== undefined || this.getError('jeepCompanyName') !== undefined || this.getError('jeepCNPJ') !== undefined || this.getError('jeepBusiness') !== undefined || this.getError('jeepMunicipalRegistration') !== undefined || this.getError('jeepStateRegistration') !== undefined || this.getError('jeepStreet') !== undefined || this.getError('jeepNumberAddress') !== undefined || this.getError('jeepComplement') !== undefined || this.getError('jeepZipCode') !== undefined || this.getError('jeepDistrict') !== undefined || this.getError('jeepCity') !== undefined || this.getError('jeepState') !== undefined || this.getError('jeepWebsite') !== undefined) {
                idElement = 'jeep-hr-client'
              } else if (this.getError('jeepFinancialContractName') !== undefined || this.getError('jeepFinancialContractMail') !== undefined || this.getError('jeepFinancialContractPhone') !== undefined) {
                idElement = 'jeep-hr-financial'
              } else if (this.getError('jeepLegalRepresentativeName') !== undefined || this.getError('jeepLegalRepresentativeMail') !== undefined || this.getError('jeepLegalRepresentativePhone') !== undefined) {
                idElement = 'jeep-hr-legal'
              } else if (this.getError('jeepMediumContractName') !== undefined || this.getError('jeepMediumContractMail') !== undefined || this.getError('jeepMediumContractPhone') !== undefined) {
                idElement = 'jeep-hr-medium'
              }
            } else {
              idElement = 'hr-brand'
            }
            this.collapseJeep = true
          }
          if (this.jeep.selected) {
            this.collapseJeep = true
          }
          if (idElement === '') {
            idElement = 'hr-brand'
          }
          document.getElementById(idElement).scrollIntoView({
            behavior: 'smooth'
          });
          return;
        }
        
        this.spinner = true

        if (this.fiat.selected) {
          if (!this.fiat.terms) {
            Swal.fire({
              title: 'Termos e Condições',
              text: 'É necessário aceitar os termos e condições de uso de Fiat.',
              icon: 'warning'
            })
            this.spinner = false
            return;
          }
          if (this.upload && !this.isAcceptFile(this.fiat.socialContract)) {
            Swal.fire({
              title: 'Atenção',
              text: 'O arquivo de Contrato Social de Fiat está no formato incorreto.',
              icon: 'warning'
            })
            this.spinner = false
            return;
          }
        }

        if (this.jeep.selected) {
          if (!this.jeep.terms) {
            Swal.fire({
              title: 'Termos e Condições',
              text: 'É necessário aceitar os termos e condições de uso de Jeep.',
              icon: 'warning'
            })
            this.spinner = false
            return;
          }
          if (this.upload && !this.isAcceptFile(this.jeep.socialContract)) {
            Swal.fire({
              title: 'Atenção',
              text: 'O arquivo de Contrato Social de Jeep está no formato incorreto.',
              icon: 'warning'
            })
            this.spinner = false
            return;
          }
        }

        if (this.updateForm) {
          if (this.upload) {
            let files = []
            if (this.fiat.selected) {
              files.push(this.fiat.socialContract)
            } else if (this.jeep.selected) {
              files.push(this.jeep.socialContract)
            }
            
            this.uploadFile(files).then(response => {
              let result = Object.values(response.data.object)
              let cont = 0
              if (this.fiat.selected) {
                this.fiat.fileNameSocialContract = result[cont++]
              }
              if (this.jeep.selected) {
                this.jeep.fileNameSocialContract = result[cont++]
              }
            }).then(() => {
              this.update()
            })
          } else {
            this.update()
          }
        } else {
          let files = []
          if (this.fiat.selected) {
            files.push(this.fiat.socialContract)
          }
          if (this.jeep.selected) {
            files.push(this.jeep.socialContract)
          }
          
          this.uploadFile(files).then(response => {
            let result = Object.values(response.data.object)
            let cont = 0
            if (this.fiat.selected) {
              this.fiat.fileNameSocialContract = result[cont++]
            }
            if (this.jeep.selected) {
              this.jeep.fileNameSocialContract = result[cont++]
            }
          }).then(() => {
            this.create()
          })
        }
      })
    },
    create () {
      this.service.create(this.fiat, this.jeep)
        .then(response => {
          if (response.status.value !== 0) {
            Swal.fire({
              title: 'Erro',
              text: response.data.object,
              icon: 'error'
            })
          } else {
            Swal.fire({
              title: 'Sucesso',
              text: response.data.object,
              icon: 'success'
            })
          
            let jeep = document.getElementById('input-jeep');
            let fiat = document.getElementById('input-fiat');
            jeep.style.marginTop = '0px'
            fiat.style.marginTop = '0px'
            this.selectedStart = -1
            this.$validator.reset()
            this.clearForm()
          }
          this.spinner = false
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.spinner = false
        })
    },
    update () {
      this.service.update(this.fiat, this.jeep)
        .then(response => {
          Swal.fire({
            title: 'Sucesso',
            text: response.data.object,
            icon: 'success'
          }).then(() => {
            window.location = window.location.origin
          })

          this.selectedStart = -1
          
          this.spinner = false
          this.errors = {}
          this.$validator.reset()
          this.clearForm()
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.spinner = false
        })
    },
    uploadFile (file) {
      return this.service.upload(file)
    },
    getTerm () {
      this.spinner = true
      this.service.getTerm()
        .then(blob => {
          window.open(URL.createObjectURL(blob), '_blank').focus()
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.spinner = false
        })
    },
    getSocialContract (brand) {
      let id = brand === 'fiat' ? this.fiat.id : this.jeep.id
      this.spinner = true
      this.service.getSocialContract(id)
        .then(blob => {
          window.open(window.URL.createObjectURL(blob))
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.spinner = false
        })
    },
    getInfo(){
      this.service.getInfo()
        .then(response => {
          response.data.object.map(x => {
            if (x.brand === 'Fiat') {
              this.fiat.items.push(x)
            } else if (x.brand === 'Jeep') {
              this.jeep.items.push(x)
            }
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
    showModal () {
      Swal.fire({
        icon: 'info',
        title: 'Link Expirado',
        text: 'Este link foi expirado, para receber um novo link no seu email, clique no botão abaixo!',
        confirmButtonText: 'Reenviar Link',
        allowOutsideClick: false,
        preConfirm: () => {
          Swal.fire({
            icon: 'info',
            title: 'Um novo link foi gerado',
            text: 'Verifique seu email',
            showConfirmButton: false,
            allowOutsideClick: false
          })
        },
      })
    },
    isAcceptFile (file) {
      const ext = file.name.substring(file.name.lastIndexOf('.')+1, file.name.length) || file.name;
      const files = ['jpg', 'jpeg', 'doc', 'docx', 'pdf', 'xls', 'xlsx', 'png']
      return files.find(x => x == ext) != null
    },
    clearForm () {
      this.fiat = {
        brand: 'Fiat',
        selected: false,
        principalId: null,
        id: null,
        fantasyName: '',
        companyName: '',
        cNPJ: '',
        business: '',
        municipalRegistration: '',
        stateRegistration: '',
        street: '',
        numberAddress: '',
        complement: '',
        zipCode: '',
        district: '',
        city: '',
        state: '',
        website: '',
        financialContractName: '',
        financialContractMail: '',
        financialContractPhone: '',
        legalRepresentativeName: '',
        legalRepresentativeMail: '',
        legalRepresentativePhone: '',
        mediumContractName: '',
        mediumContractMail: '',
        mediumContractPhone: '',
        numberStores: '',
        monthlyFee: '',
        socialContract: null,
        fileNameSocialContract: null,
        terms: false,
        items: this.fiat.items,
        upload: false,
        selectedStart: -1,
        model: 4
      }
      this.jeep = {
        brand: 'Jeep',
        selected: false,
        principalId: null,
        id: null,
        fantasyName: '',
        companyName: '',
        cNPJ: '',
        business: '',
        municipalRegistration: '',
        stateRegistration: '',
        street: '',
        numberAddress: '',
        complement: '',
        zipCode: '',
        district: '',
        city: '',
        state: '',
        website: '',
        financialContractName: '',
        financialContractMail: '',
        financialContractPhone: '',
        legalRepresentativeName: '',
        legalRepresentativeMail: '',
        legalRepresentativePhone: '',
        mediumContractName: '',
        mediumContractMail: '',
        mediumContractPhone: '',
        numberStores: '',
        monthlyFee: '',
        socialContract: null,
        fileNameSocialContract: null,
        terms: false,
        items: this.jeep.items,
        selectedStart: -1,
        model: 2
      }
    },
    removeBrandName (field) {
      if (field.includes('fiat') || field.includes('jeep')) {
        let newField = field.replace('fiat', '').replace('jeep', '')
        return newField.charAt(0).toLowerCase() + newField.slice(1);
      }
      return field
    }
  },
  mounted() {
    document.title = 'Stellantis - Adesão ao Programa DDM - Blinks Essence'
    
    this.service = new FormService(this.$http)

    this.getInfo()

    const user = this.$route.query.user
    if (user) {
      this.clearForm()
      this.getByLink(user)
      this.updateForm = true
    } else {
      Swal.fire({
        icon: 'info',
        title: 'Atenção',
        html: `
          Este link está expirado. <br>
          Clique no <a href='https://formulario.blinksessence.com.br/'>novo link</a> para ser redirecionado
        `,
        showConfirmButton: false,
        allowOutsideClick: false
      })
    }
  }
}
</script>

<style>
  .min-heigth-53vh {
    min-height: 53vh;
  }

  .spinner {
    background: rgba(0, 0, 0, 0.1);
    top: 0;
    z-index: 999999;
    position: fixed;
    width: 100%;
    height: 100%;
  }

  .spinner-item {
    margin-top: 45vh;
  }

  .background-gainsboro {
    background: gainsboro;
  }

  .black-table {
    text-align: center;
  }

  .black-table th {
    color: #fff;
    background-color: #343a40;
    border-color: #454d55;
  }

  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Procurar";
  }

  .w-fit-content{
    width: fit-content;
  }

  body {
    background: #F5F5F5;
  }

  .button-as-link {
    border: none;
    padding: 0px 2px 4px;
  }

  /* Radio Button */
  .middle {
    width: 100%;
    text-align: center;
  }
  
  .middle input[type=checkbox] {
    display: none;
  }

  .middle input[type=checkbox]:checked + .box {
    background-color: #007e90;
  }

  .middle input[type=checkbox]:checked + .box div {
    color: white;
    transform: translateY(70px);
  }

  .middle input[type=checkbox]:checked + .box div:before {
    transform: translateY(0px);
    opacity: 1;
  }
  
  .middle .box {
    width: 200px;
    height: 200px;
    background-color: white;
    transition: all 250ms ease;
    will-change: transition;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    position: relative;
    font-weight: 900;
  }

  .middle .box div {
    position: absolute;
    transform: translate(0, 60px);
    left: 0;
    right: 0;
    transition: all 300ms ease;
    font-size: 1.5em;
    user-select: none;
    color: #007e90;
  }

  .middle .box div:before {
    font-size: 1.2em;
    font-family: FontAwesome;
    display: block;
    transform: translateY(-80px);
    opacity: 0;
    transition: all 300ms ease-in-out;
    font-weight: normal;
    color: white;
  }
  
  .middle p {
    color: #FFF;
    font-weight: 400;
  }

  .middle p a { 
    text-decoration: underline;
    font-weight: bold;
    color: #fff;
  }

  .table.b-table.b-table-selectable:not(.b-table-selectable-no-click) > tbody > tr {
    cursor: default;
  }

  @media only screen and (max-width: 600px) {
    .h2-title {
      font-size: 22px;
      font-weight: 500;
    }
  }
</style>