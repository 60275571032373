<template>
  <div class="mt-4">
    <h6 class="font-13-rem font-weight-normal">{{ title }}</h6>
    <hr class="mt-10px mb-20px hr-border">
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  },
  data: function(){
    return {
    }
  }
}
</script>

<style>
  .mt-10px{
    margin-top: 10px;
  }

  .mb-20px{
    margin-bottom: 20px;
  }

  .hr-border {
    border-top: 1px solid rgba(0, 0, 0, 1);
  }

  .font-13-rem {
    font-size: 1.3rem;
  }
</style>